<template>
    <base-modal
        v-if="ativo"
        :telaInteira="false"
        :tituloModal="'a'"
        :classesAdicionaisModal="'rounded'"
        :classesAdicionaisHeader="'d-none'"
        :classesAdicionaisFooter="'d-none'"
        :classesAdicionaisBody="'p-0'"
        id="modalLeitorCodigoBarras"
    >
        <div slot="body" class="bg-dark">
            <div class="h5 text-center text-white">Aponte a camera para o {{ tipoLeitura }} desejado</div>
            <div class>
                <StreamBarcodeReader @decode="retornoCodigoBarras" @loaded="onLoaded" />
            </div>
            <div class="text-center mt-2 pb-2">
                <a id="lmxta-leitor-btn-cancelar" class="btn btn-default" @click="cancelar">CANCELAR</a>
            </div>
        </div>
    </base-modal>
</template>

<script>
import baseModal from '@/components/base-modal.vue';
import { StreamBarcodeReader } from 'vue-barcode-reader';
import { mapGetters } from 'vuex';

export default {
    name: 'LeitorCodigoBarras',
    components: {
        baseModal,
        StreamBarcodeReader
    },
    props: {
        ativo: {
            type: Boolean,
            default: false
        },
        tipoLeitura: {
            type: String,
            default: 'código de barras'
        }
    },
    data() {
        return {};
    },

    created: function() {},

    computed: {
        ...mapGetters({
            utilizaPaymentTerminal: 'uiState/utilizaPaymentTerminal'
        })
    },
    methods: {
        retornoCodigoBarras(result) {
            this.$emit('input', { codigoBarras: result });
        },
        cancelar() {
            this.$emit('input', { codigoBarras: null });
        },
        onLoaded(result) {
            console.log('onLoaded', result);
        }
    }
};
</script>
